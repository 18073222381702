var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items && !_vm.items.length)?_c('i',[_vm._v(_vm._s(_vm.$t("noDataAvailable")))]):_vm._e(),_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticStyle:{"margin-bottom":"10px"}},[_c('CardActionable',{class:{
        'view-only': _vm.viewOnly,
        'border-none': _vm.viewOnly && i == _vm.items.length - 1,
      },attrs:{"actions":_vm.actions,"data":item,"view-only":_vm.viewOnly},on:{"click":function($event){return _vm.select($event, item)}},scopedSlots:_vm._u([(_vm.type === 'Experience')?{key:"default",fn:function(ref){
      var value = ref.value;
return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(value.company_name))]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(value.position)+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm._f("moment")(value.start_time,"MMMM YYYY"))+" - "),(value.end_time)?[_vm._v(" "+_vm._s(_vm._f("moment")(value.end_time,"MMMM YYYY"))+" ")]:[_vm._v(_vm._s(_vm.$t("present")))]],2)]}}:(_vm.type === 'SoftSkills')?{key:"default",fn:function(ref){
      var value = ref.value;
return [(value)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(value.skill[_vm.$i18n.locale])+" "),_c('span',[_c('v-rating',{attrs:{"readonly":""},model:{value:(value.rating),callback:function ($$v) {_vm.$set(value, "rating", $$v)},expression:"value.rating"}})],1)]):_vm._e()]}}:{key:"default",fn:function(ref){
      var value = ref.value;
return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(value.university_name))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(value.study))]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm._f("moment")(value.start_time,"MMMM YYYY"))+" - "),(value.end_time)?[_vm._v(" "+_vm._s(_vm._f("moment")(value.end_time,"MMMM YYYY"))+" ")]:[_vm._v(_vm._s(_vm.$t("present")))]],2)]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }